*, *::before, *::after {
  box-sizing: border-box;
}

body {
  background-color: #F3F3F3;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.container .ql-editor {
  width: 8.5in;
  min-height: 11in;
  padding: 1in;
  margin: 1rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
  background-color: white;
  border-radius: 16px;
}

.container .ql-container.ql-snow {
  border: none;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

.container .ql-toolbar.ql-snow {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #e1e1e1;
  border: none;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5);
  border-radius: 0px 0px 16px 16px;
}

@page {
  margin: 1in;
}

@media print {

  @page {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1in;
    margin-right: 1in;
}
body {
   padding-top: 72px;
   padding-bottom: 72px ;
    background: none;
  }

  .container .ql-editor {
    width: 6.5in;
    height: 9in;
    padding: 0;
    margin: 0;
    box-shadow: none;
    align-self: flex-start;
  }

  .container .ql-toolbar.ql-snow {
    display: none;
  }

  .title-container {
    display: none !important;
  }

  .focus-list {
    display: none !important;
  }

  .focus-item {
    display: none !important;
  }

  .focus-item-on {
    display: none !important;
  }

  .suggest-button {
    display: none !important;
  }

  .suggestion {
    display: none !important;
  }

  .comment-button {
    display: none !important;
  }

  .comment-box {
    display: none !important;
  }

  .feedback-button {
    display: none !important;
  }

  .feedback {
    display: none !important;
  }

  .comment {
    display: none !important;
  }

  .titlebar {
    display: none !important;
  }

  .close-suggest {
    display: none !important;
  }

  .publish {
    display: none !important;
  }

  .update { 
    display: none !important;
  }

  .divider {
    display: none !important;
  }

  .add-focus {
    display: none !important;
  }

  .add-focus-on {
    display: none !important;
  }

  .sug-input {
    display: none !important;
  }

  .flex {
    display: none !important;
  }

  .hoverable {
    display: none !important;
  }

  a[href]:after {
    content: none !important;
  }
}

.title-container {
  justify-content: left;
  display: flex;
  background-color: #e1e1e1;
  height: 50px;
  padding: 10px;
  z-index: 2;
}

.titlebar {
  background-color: #e1e1e1;
  font-size: 20px;
  font-weight: 550;
  justify-content: left;
  display: inline-grid;
  padding: 6px;
  border: none;
}

*:focus {
  outline: 1px solid #9cbfff;
  border-radius: 16px;
}

.suggestion {
  z-index: 2;
  position: fixed;
  bottom: 30px;
  right: 20px;
  width: 300px;
  min-height: 35px;
  background-color: #9cbfff;
  border: #076eff;
  border-width: 2px;
  border-radius: 16px;
  inline-size: 305px;
  padding: 15px;
  overflow-wrap: break-word;
  font-size: 0.8rem;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
}

.suggest-button {
  font-size: 13px;
  z-index: 2;
  position: fixed;
  right: 165px;
  bottom: 25px;
  background-color: #9cbfff;
  border: #076eff;
  border-width: 2px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 20px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.suggest-button:active {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}

.close-suggest {
  position: fixed;
  z-index: 2;
  right: 30px;
  bottom: 15px;
  border-radius: 3px;
  font-size: 12px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.close-suggest:hover {
  text-decoration: underline;
}

.close-suggest:active {
  text-decoration: underline;
}

.sug-input {
  z-index: 2;
  position: fixed;
  bottom: 0px;
  right: 235px;
  border-radius: 25%;
  height: 30px;
  font-size: 12px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.comment-box {
  position: absolute;
  z-index: 1;
  width: 3.3in;
  right: 15px;
  top: 200px;
  display: flex;
  flex-direction: column;            
  justify-content: space-between;
}

.comment-button {
  z-index: 3;
  position: absolute;
  right: 165px;
  top:130px;
  background-color: #9cbfff;
  border: #076eff;
  border-width: 2px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 4px 2px;
  border-radius: 20px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.feedback-button {
  z-index: 3;
  position: absolute;
  left: 15px;
  top:130px;
  font-size: 13px;
  background-color: #9cbfff;
  border: #076eff;
  border-width: 2px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 20px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.feedback {
  z-index: 3;
  left: 15px;
  top:175px;
  position: absolute;
  background-color: rgb(210, 207, 214);
  border: rgb(169, 169, 173);
  border-width: 2px;
  border-radius: 16px;
  width: 3.2in;
  padding: 15px;
  margin-top: 10px;
  text-align: left;
  font-size: 0.8rem;
  opacity: 80%;

}

.loading {
  z-index: 3;
  position: absolute;
  right: 125px;
  top:132px;
}

.comment-button:active {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}

.comment {
  background-color: rgb(210, 207, 214);
  border: rgb(169, 169, 173);
  border-width: 2px;
  border-radius: 16px;
  min-width: 3.2in;
  padding: 15px;
  margin-top: 10px;
  text-align: left;
  font-size: 0.8rem;
  opacity: 80%;
}

.comment:hover {
  box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.focus-list {
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  background-color: #e1e1e1;
  padding-left: 10px;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.focus-list::-webkit-scrollbar {
  display: none;
}

.focus-item {
  text-align:center;
  border-radius: 16px;
  margin-right: 5px;
  padding: 6px;
  font-size: 13px;
  background-color: rgb(210, 207, 214);
  border: rgb(169, 169, 173);
  border-width: 2px;
  border-radius: 16px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.focus-item-on {
  text-align:center;
  border-radius: 16px;
  margin-right: 5px;
  padding: 6px;
  font-size: 13px;
  background-color: #9cbfff;
  border: rgb(169, 169, 173);
  border-width: 2px;
  border-radius: 16px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.focus-add {
  z-index: 10;
  position: absolute;
  top: 85px;
  left: 10px;
  padding: 15px;
  opacity: 90%;
  background-color: rgb(229, 225, 235);
  border-radius: 16px;
  padding: 5px;
}

.margin-focus {
  margin-top: 5px;
}

.divider {
  border-left:2px solid #9cbfff; 
  border-right:1px solid #9cbfff; 
  border-radius: 2px;
  margin-right: 5px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.publish {
  z-index: 5;
  position: absolute;
  right: 40px;
  top:10px;
  background-color: #9cbfff;
  border: #076eff;
  border-width: 2px;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 20px;
  font-size: 15px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.publish:active {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}

.update {
  z-index: 5;
  position: absolute;
  right: 160px;
  top:10px;
  font-size: 15px;
  background-color: #9cbfff;
  border: #076eff;
  border-width: 2px;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 20px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.update:active {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}

@keyframes ldio-x2uulkbinbj {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-x2uulkbinbj div { box-sizing: border-box!important }
.ldio-x2uulkbinbj > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #7031d3 transparent #7031d3 transparent;
  animation: ldio-x2uulkbinbj 2.941176470588235s linear infinite;
}

.ldio-x2uulkbinbj > div:nth-child(2), .ldio-x2uulkbinbj > div:nth-child(4) {
  width: 54px;
  height: 54px;
  top: 23px;
  left: 23px;
  animation: ldio-x2uulkbinbj 2.941176470588235s linear infinite reverse;
}
.ldio-x2uulkbinbj > div:nth-child(2) {
  border-color: transparent #6277ec transparent #6277ec
}
.ldio-x2uulkbinbj > div:nth-child(3) { border-color: transparent }
.ldio-x2uulkbinbj > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-x2uulkbinbj > div:nth-child(3) div:before, .ldio-x2uulkbinbj > div:nth-child(3) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: #7031d3;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #7031d3;
}
.ldio-x2uulkbinbj > div:nth-child(3) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 #7031d3;
}

.ldio-x2uulkbinbj > div:nth-child(4) { border-color: transparent; }
.ldio-x2uulkbinbj > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-x2uulkbinbj > div:nth-child(4) div:before, .ldio-x2uulkbinbj > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 15px;
  background: #6277ec;
  border-radius: 50%;
  box-shadow: 0 46px 0 0 #6277ec;
}
.ldio-x2uulkbinbj > div:nth-child(4) div:after {
  left: -8px;
  top: 15px;
  box-shadow: 46px 0 0 0 #6277ec;
}
.loadingio-spinner-double-ring-nq4q5u6dq7r {
  width: 38px;
  height: 38px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-x2uulkbinbj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.38);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-x2uulkbinbj div { box-sizing: content-box; }

.add-focus {
  text-align:center;
  align-content: center;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 21px;
  color: rgb(105, 105, 110);
  text-align: center;
  width: 28px;
  background-color: rgb(210, 207, 214);
  border: rgb(169, 169, 173);
  border-width: 2px;
  border-radius: 16px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.add-focus-on {
  text-align:center;
  align-content: center;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 21px;
  color: rgb(105, 105, 110);
  text-align: center;
  width: 28px;
  background-color: #9cbfff;
  border: rgb(169, 169, 173);
  border-width: 2px;
  border-radius: 16px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.hoverable {transition: opacity 0.3s ease;}
.hoverable:hover {cursor: pointer; opacity: 80%;}
